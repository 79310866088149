.pagination-container{
    display: flex;
    width:100vw;
    margin-bottom: 50px;
}

.pagination-content-container{
    display:block;
    margin: 0px auto;
}

.pagination-elipsis{
    margin: 0px 6px !important;
}

.pagination-page{
    display: inline-flex;
    padding: 6px 6px;
    margin: 0px 2px;
    justify-content: center;
    font-weight: 700;
    border: 1px solid transparent;
    color: #0a5f90;
    font-family: 'Kameron', serif;
    user-select: none;
    border-radius: 3px;
}

.pagination-page:hover:not(.pagination-elipsis){
    color: #1aa5f5;
    border: 1px solid #1aa5f5;
    cursor: pointer;
}

.pagination-page:active:not(.pagination-elipsis), .pagination-page.active{
    color: white !important;
    border: 1px solid transparent;
    cursor: pointer;
    background: #1aa5f5;
}


@media all and (max-width:575px){
    .pagination-page{
        margin: 0px;
        padding: 6px 2px;
    }

    .pagination-elipsis{
        margin: 0px 2px !important;
    }

    .pagination-page.double-back,
    .pagination-page.back,
    .pagination-page.double-forward,
    .pagination-page.forward
    {
        display:none;
    }
}