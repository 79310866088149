.content-header {
  height: 60px;
  padding: 10px 40px;
  background: white;
  box-shadow: 0px 1px 20px 0px grey;
}

.content-header-inner-container {
  display: flex;
  align-items: center;
  max-width: 75%;
  margin: auto;
}

.content-header h3 {
  font-size: 1.5em;
  margin-right: 15px;
}

@media all and (min-width: 1921px) {
  .content-header-inner-container {
    max-width: 1450px;
  }
}

@media all and (max-width: 1199px) {
  .content-header-inner-container {
    max-width: 85%;
  }
}
@media all and (max-width: 991px) {
  .content-header-inner-container {
    max-width: 90%;
  }
}
@media all and (max-width: 768px) {
  .content-header-inner-container {
    max-width: 90%;
  }
  .content-header h3{
    display: none;
  }
}
@media all and (max-width: 575px) {

  .content-header{
    padding: 10px 5px;
  }

  .content-header-inner-container{
    width:100%;
  }

  .search-bar{
    position: absolute;
    top: 160px;
    left: 0px;
    background: white;
    width: 100%;
    border: transparent;
    border-radius: 0px;
    box-shadow: 0px 0 1px grey;
  }

  .content-container{
    margin-top: 20px;
  }
}
