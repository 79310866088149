.movie-details-container{
    width: 65%;
    margin: 20px auto 0px auto;
    font-size: 1.2em;
    max-width: 1200px;
}

.main-movie-details-container{
    display: flex;
    align-items: flex-start;
}

.movie-details-side-container{
    padding: 0px 10px 0px 50px;
}

.movie-details-side-container p{
    margin-bottom: 7px;
}

.movie-details-title{
    font-size: 2.5em !important;
    margin-bottom: 15px;
}

.movie-details-cast-container{
    margin: 20px auto 60px auto;
    width: 100%;
    max-width: 1200px;
    overflow-x: scroll;
    display: flex; 
}

.movie-details-side-header{
    margin-top: 40px;
    font-size: 1.8em;
}

.movie-details-back-button{
    width:65%;
    margin: 0px auto;
    padding-top: 40px;
    cursor: pointer;
}

.movie-details-sub-head{
    font-weight: 700;
}

.main-movie-details-poster{
    width:300px;
    /* align-self: flex-end; */
    margin-top: 12px;
}

.main-movie-details-backdrop{
    width:100%;
    display:none;
}



@media all and (min-width: 1921px) {
    
  }
  
  @media all and (max-width: 1199px) {
    
  }
  @media all and (max-width: 991px) {
    .main-movie-details-backdrop{
        width:100%;
        display:block;
    }

    .main-movie-details-poster{
        display: none;
    }

    .movie-details-side-container{
        padding: 0px;
    }

    
  }
  @media all and (max-width: 768px) {
    
  }
  @media all and (max-width: 575px) {
    .movie-details-container{
        width:92%;
        font-size:0.9em;
    }
    .movie-details-back-button{
        width: 92%;
    }
  }
  