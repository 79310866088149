@import url("https://fonts.googleapis.com/css?family=Montserrat|Kameron:700");

html,
body {
  height: 100vh;
  width: 100vw;
  padding: 0px;
  background: #f2f2f2;
}

* {
  box-sizing: border-box;
  margin: 0px;
}

#site-header {
  height: 100px;
  padding: 10px 40px;
  background: #0f1a4e;
  z-index: 20;
  position: relative;
}

.site-header-inner-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  max-width: 75%;
  margin: auto;
}

#site-header h1 {
  margin: 0px;
  color: #fff;
}

@media all and (min-width: 1921px) {
  .site-header-inner-container {
    max-width: 1450px;
  }
}

@media all and (max-width: 1199px) {
  .site-header-inner-container {
    max-width: 85%;
  }
}
@media all and (max-width: 991px) {
  .site-header-inner-container {
    max-width: 90%;
  }
}
@media all and (max-width: 768px) {
  .site-header-inner-container {
    max-width: 90%;
  }
}
@media all and (max-width: 575px) {
}
