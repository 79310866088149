.movie_card{
    width: 250px;
    min-width: 220px;
    background: white;
    position: relative;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.2;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 0px 10px 0px grey;
    margin: 0px 10px 40px 10px;
}

.movie_card_poster{
    width: 100%;
    margin:0px;
    display:block;
    min-height: 365px;
}

.movie_card_details_container{
    background: rgb(255, 255, 255);
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 5px 5px 5px 8px;
    gap: 0.4rem;
}

.movie_card_user_score{
    width: 15%;
}

.movie_card_details_text_container{
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: 0px 10px 0px 0px;
}
.movie_card_details_text_container div{
    display: flex;
    padding-left: 2px;
}

.movie_card_details_text_container p{
    margin: 0px;
}

.movie_card_title{
    font-weight: 800;
    max-width: 100%;
    max-height: 1.2em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 5px !important;
    font-size: 13px;
}

.movie_card_year, .movie_card_language{
    font-size: 12px;
}

.movie_card_year::after{
    content: '\00a0 | \00a0'
}

.CircularProgressbar {
    height:70%;
}

.CircularProgressbar-path       { stroke: red; }
.CircularProgressbar-trail      { stroke: gray; }
